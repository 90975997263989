import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  senderCreateFailure,
  senderCreateSuccess,
  sendersListFailure,
  sendersListSuccess,
  senderViewFailure,
  senderViewSuccess,
} from "../slices/SendersSlice";

function* sendersListAPI(action) {
  yield getCommonSaga({
    apiUrl: "senders",
    payload: action.payload,
    successNotify: false,
    success: sendersListSuccess,
    failure: sendersListFailure,
  });
}

function* senderCreateAPI(action) {
  yield getCommonSaga({
    apiUrl: "senders/store",
    payload: action.payload,
    successNotify: true,
    success: senderCreateSuccess,
    failure: senderCreateFailure,
  });
}

function* senderViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "senders/show",
    payload: action.payload,
    successNotify: false,
    success: senderViewSuccess,
    failure: senderViewFailure,
  });
}

export default function* SendersSaga() {
  yield all([
    yield takeLatest("senders/sendersListStart", sendersListAPI),
    yield takeLatest("senders/senderCreateStart", senderCreateAPI),
    yield takeLatest("senders/senderViewStart", senderViewAPI),
  ]);
}
