import React, { useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { senderViewStart } from "../../store/slices/SendersSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import iso from "iso-3166-1"

const SenderViewDetail = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const senderView = useSelector((state) => state.senders.senderView);

  useEffect(() => {
    dispatch(
      senderViewStart({
        sender_id: params.id,
      })
    );
  }, []);

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="payment-table-header">
            <Link to={-1}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                data-name="Layer 2"
                viewBox="0 0 24 24"
              >
                <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
              </svg>
            </Link>
            <h3>View Sender</h3>
          </div>
          {senderView.loading ? (
            <>
              <Row>
                <Col md={6}>
                  <Skeleton height={200} width={"100%"} />
                </Col>
                <Col md={6}>
                  <Skeleton height={200} width={"100%"} />
                </Col>
              </Row>
            </>
          ) : Object.keys(senderView.data).length > 0 ? (
            Object.keys(senderView.data.sender).length > 0 ? (
              <React.Fragment>
                <div className="list-view-card-row">
                  <div className="list-view-card">
                    <div className="list-view-card-info">
                      <p>Unique ID</p>
                      <h4>{senderView.data.sender.unique_id}</h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>Name</p>
                      <h4>{senderView.data.sender.first_name + " " + senderView.data.sender.middle_name + " " + senderView.data.sender.last_name}</h4>
                    </div>
                    {senderView.data.sender.mobile ? <div className="list-view-card-info">
                      <p>Mobile</p>
                      <h4>{"+" + senderView.data.sender.mobile_country_code + " " + senderView.data.sender.mobile}</h4>
                    </div> : null}
                    <div className="list-view-card-info">
                      <p>Sender Type</p>
                      <h4>{senderView.data.sender.type_formatted}</h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>Status</p>
                      <h4>
                        {senderView.data.sender.status_formatted}
                      </h4>
                    </div>
                  </div>
                  <div className="list-view-card">
                    {senderView.data.sender.email ? <div className="list-view-card-info">
                      <p>Email</p>
                      <h4>{senderView.data.sender.email}</h4>
                    </div> : null}
                    {senderView.data.sender.source_of_funds ? <div className="list-view-card-info">
                      <p>Source Of Funds</p>
                      <h4>{senderView.data.sender.source_of_funds}</h4>
                    </div> : null}
                    <div className="list-view-card-info">
                      <p>Address</p>
                      <h4>{senderView.data.sender.address}</h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>Country</p>
                      <h4>{iso.whereAlpha2(senderView.data.sender.country).country}</h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>Created At</p>
                      <h4>{senderView.data.sender.created_at_formatted}</h4>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <NoDataFound />
            )
          ) : null}
        </div>
      </div>
    </>
  );
};
export default SenderViewDetail;
