import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Form, Row, Col, Image } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Field, Form as FORM, Formik, ErrorMessage } from "formik";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { sendersListStart } from "../../store/slices/SendersSlice";
import * as Yup from "yup";
import { ButtonLoader } from "../Helper/Loader";

const SelectSender = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("quote.quote_transfer");
  const sendersList = useSelector((state) => state.senders.sendersList);
  const [senders, setSenders] = useState([]);
  const createTransaction = useSelector(
    (state) => state.beneficiaries.createTransaction
  );

  const [selectedSender, setSelectedSender] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid  #DDE1E6 !important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      color: "#757575",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   return {
    //     ...styles,
    //     backgroundColor: isSelected ? "#f7f7f7" : "#fff",
    //     color: "#000",
    //   };
    // },
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#0194ff" : "#fff",
      color: state.isFocused || state.isSelected ? "#fff" : "#000",
      ":hover": {
        backgroundColor: "#0194ff",
        color: "#fff",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    sender_id: Yup.number()
      .required(t("required"))
  });

  useEffect(() => {
    props.transferData &&
      dispatch(
        sendersListStart({
          receiving_currency: props.transferData.receiving_currency,
          recipient_type: props.transferData.recipient_type,
        })
      );
  }, [props]);

  useEffect(() => {
    if (
      !skipRender &&
      !sendersList.loading &&
      Object.keys(sendersList.data).length > 0
    ) {
      sendersList.data.total_senders > 0 &&
        setSenders(
          sendersList.data.senders.map((data) => ({
            label: `${data.first_name + " " + data.middle_name + " " + data.last_name}`,
            value: data.id,
          }))
        );
    }
    setSkipRender(false);
  }, [sendersList]);

  const handleSubmit = (values) => {
    props.setTransferData({
      ...props.transferData,
      ...values,
    });
    props.setStep(props.step + 1);
  };

  return (
    <div className="select-sender">
        
      <div className="efi-feature-header-frame layout-between">
      <div className="single-transaction-back">
          <Button
            className="efi-overlay-btn"
            onClick={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default action when pressing Enter
                return;
              }
              props.setStep(props.step - 1);
            }}
            // disabled={updateAddress.buttonDisable}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); 
              }
            }}
            disabled={createTransaction.buttonDisable}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 24 24"
            >
              <path
                fill="#0194ff"
                d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Back</span>
          </Button>
        </div>
        <div className="efi-feature-transation-search-frame">
          <Button
            className="efi-primary-btn"
            onClick={() => navigate("/add-sender")}
          >
            Add Sender
          </Button>
        </div>
      </div>
      {selectedSender ? null : <div className="single-transaction-box-avater">
          <Image
            className="trans-beneficiary-avater"
            src={window.location.origin + "/img/beneficiary-select.svg"}
            type="image/png"
          />
        </div>}
      <div className="mb-4">
        <Formik
          initialValues={{
            sender_id: "",
            quote_id: props.transferData.quote.id,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, touched, errors, resetForm }) => (
            <FORM className="efi-bank-default-form w-100">
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("sender.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={senders}
                      styles={customStyles}
                      value={selectedSender ? {
                        label: `${selectedSender.first_name + " " + selectedSender.middle_name + " " + selectedSender.last_name}`,
                        value: selectedSender.id,
                      } : null}
                      classNamePrefix="react-select"
                      onChange={(data) => {
                        data !== null && setSelectedSender(sendersList.data.senders.find((sender) => sender.id === data.value));
                        setFieldValue("sender_id", data.value);
                      }}
                      placeholder={t("sender.placeholder")}
                      isDisabled={sendersList.buttonDisable}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="sender_id"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="onboarding-auth-footer mt-3">
                <Button
                  className="efi-primary-btn w-100"
                  type="submit"
                  disabled={createTransaction.buttonDisable}
                >
                  {createTransaction.buttonDisable ? <ButtonLoader /> : "Next"}
                </Button>
              </div>
            </FORM>
          )}
        </Formik>
      </div>
      {selectedSender ? <div className="select-detail-box">
        <div className="beneficiary-detail-card">
          <div className="beneficiary-detail-info">
            <p>Unique ID</p>
            <h4>{selectedSender.unique_id}</h4>
          </div>
          <div className="beneficiary-detail-info">
            <p>Type</p>
            <h4>{selectedSender.type_formatted}</h4>
          </div>
          <div className="beneficiary-detail-info">
            <p>Account Holder Name</p>
            <h4>{`${selectedSender.first_name + " " + selectedSender.middle_name + " " + selectedSender.last_name}`}</h4>
          </div>
          <div className="beneficiary-detail-info">
            <p>Address</p>
            <h4>{selectedSender.address}</h4>
          </div>
          <div className="beneficiary-detail-info">
            <p>Phone Number</p>
            <h4>{"+" + selectedSender.mobile_country_code + " " + selectedSender.mobile}</h4>
          </div>
          <div className="beneficiary-detail-info">
            <p>Created At</p>
            <h4>{selectedSender.created_at_formatted}</h4>
          </div>
        </div>
      </div> : null}
    </div>
  );
};

export default SelectSender;
