import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Field, Form as FORM, Formik } from "formik";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { businessUserListStart } from "../../store/slices/BusinessSlice";
import NoDataFound from "../Helper/NoDataFound";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import Skeleton from "react-loading-skeleton";
import configuration from "react-global-configuration";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import Pagination from "../Helper/Pagination";

const BusinessUserList = (props) => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.admin.profile);
  const t = useTranslation("");
  const dispatch = useDispatch();
  const businessUserList = useSelector(
    (state) => state.business.businessUserList
  );
  const [searchParams] = useSearchParams();
  const formRef = useRef(null);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );

  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
  });

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        businessUserListStart({
          ...filter,
          skip: 12 * (page - 1),
          take: 12,
        })
      );
    }
  }, [profile, filter, page]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/business-user-list?${params}page=${selected + 1}`);
  };

  return (
    <>
      <div className="full-body-card-wrapped">

        <div className="contact-list-card-area">
          <div className="payment-table-header justify-content-between">
            <h3>Business User List</h3>
            <div className="card-details-head-action">
              <Formik
                initialValues={{
                  search_key: searchParams.get("search_key")
                    ? searchParams.get("search_key")
                    : "",
                }}
                validationSchema={validationSchema}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    search_key: val.search_key,
                  });

                  navigate(
                    searchParams.get("sort_by")
                      ? `/business-user-list?search_key=${val.search_key
                      }&sort_by=${searchParams.get("sort_by")}`
                      : `/business-user-list?search_key=${val.search_key}`
                  );
                }}
                innerRef={formRef}
              >
                {({ values, setFieldValue, resetForm }) => (
                  <FORM className="form">
                    <div className="efi-transation-search">
                      <InputGroup className="mb-0">
                        <Field
                          // placeholder={t("search_placeholder")}
                          placeholder={"Search"}
                          type="text"
                          className="form-control trans-form-control"
                          name="search_key"
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              formRef.current.handleSubmit();
                            }
                          }}
                        />
                        {searchParams.get("search_key") ? (
                          <InputGroup.Text id="basic-addon1">
                            <Button
                              className="search-btn"
                              onClick={() => {
                                setFieldValue("search_key", "");
                                setFilter({
                                  ...filter,
                                  search_key: "",
                                });
                                navigate(
                                  searchParams.get("sort_by")
                                    ? `/business-user-list?sort_by=${searchParams.get(
                                      "sort_by"
                                    )}`
                                    : `/business-user-list`
                                );
                              }}
                            >
                              <svg
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                focusable="false"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                              >
                                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                              </svg>
                            </Button>
                          </InputGroup.Text>
                        ) : (
                          <InputGroup.Text id="basic-addon1">
                            <Button className="search-btn" type="submit">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              // class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                <path d="M21 21l-6 -6" />
                              </svg>
                            </Button>
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                    </div>
                  </FORM>
                )}
              </Formik>
              <Link
                className="efi-primary-btn"
                to="/create-business-profile"
              >
                Create New User
              </Link>
            </div>
          </div>
          {businessUserList.loading ?
            <>
              {[...Array(3)].map((_, key) => (
                <Skeleton key={key} height={50} width={"100%"} />
              ))}
            </>
            : Object.keys(businessUserList.data).length > 0 &&
              businessUserList.data.users.length > 0 ?
              <div>
                <Table className="common-table" responsive>
                  <thead className="common-table-header">
                    <tr>
                      <th>Unique Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Service Type</th>
                      <th>KYC Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="common-table-body">
                    {businessUserList.data.users.map((item, i) => (
                      <tr key={i}>
                        <td>{item.user_unique_id}</td>
                        <td>
                          {item.title +
                            " " +
                            item.first_name +
                            " " +
                            item.last_name}
                        </td>
                        <td>{item.email}</td>
                        <td>{item.service_type_formatted}</td>
                        <td>
                          {item.service_type == 1
                            ? item.mastercard_kyc_status_formatted
                            : item.iban_kyc_status_formatted}
                        </td>
                        <td className="delete-contact-btn-sec">
                          <Link
                            to={`/business-user-list-view/${item.user_unique_id}`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="12"
                              fill="none"
                              viewBox="0 0 18 12"
                            >
                              <path
                                fill="#171717"
                                fillRule="evenodd"
                                d="M5.875 6a3.125 3.125 0 116.25 0 3.125 3.125 0 01-6.25 0zM9 4.125a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75z"
                                clipRule="evenodd"
                              ></path>
                              <path
                                fill="#171717"
                                fillRule="evenodd"
                                d="M2.603 4.872c-.35.503-.478.897-.478 1.128 0 .231.129.625.478 1.128.337.487.84 1.014 1.474 1.501C5.35 9.606 7.095 10.375 9 10.375c1.905 0 3.65-.77 4.923-1.746.635-.487 1.136-1.014 1.474-1.501.35-.503.478-.897.478-1.128 0-.231-.129-.625-.478-1.128-.338-.487-.84-1.014-1.474-1.501C12.65 2.394 10.905 1.625 9 1.625c-1.905 0-3.65.77-4.923 1.746-.635.487-1.137 1.014-1.474 1.501zm.713-2.493C4.758 1.273 6.763.375 9 .375s4.242.898 5.684 2.004c.722.555 1.319 1.173 1.74 1.78.41.59.701 1.239.701 1.841 0 .602-.291 1.25-.7 1.84-.422.608-1.019 1.226-1.741 1.78-1.442 1.107-3.447 2.005-5.684 2.005s-4.242-.898-5.684-2.004c-.722-.555-1.319-1.173-1.74-1.78C1.166 7.25.875 6.602.875 6c0-.602.291-1.25.7-1.84.422-.608 1.019-1.226 1.741-1.78z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {businessUserList.data.total_users > 12 ? (
                  <div className="new-billings-pagination-sec">
                    <Pagination
                      page={page}
                      pageCount={Math.ceil(
                        businessUserList.data.total_users / 12
                      )}
                      handlePageClick={handlePageClick}
                    />
                  </div>
                ) : null}
              </div>
              :
              <div className="common-full-card">
                <NoDataFound />
              </div>
          }
        </div>
      </div>
    </>
  );
};
export default BusinessUserList;
