import React from 'react';
import { Navbar, Container, Image, Button } from "react-bootstrap";
import Sidebar from './Sidebar';
import CollapseSidebar from './CollapseSidebar';
import { useDispatch, useSelector } from 'react-redux';
import ReactJoyride from "react-joyride";
import { tourStatusUpdateStart } from "../../../store/slices/AdminSlice";


export default function SidebarIndex(props) {
    const sidebar = useSelector(state => state.common.sidebar);
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.admin.profile);

    const Step1 = () => {
        return (
          <div className="tour-step">
            <Image
              src={window.location.origin + "/img/manage-account.svg"}
              className="tour-step-img"
            />
            <p>
            Welcome to EFIcyent! We are delighted to have you onboard. Our platform is tailored for seamless product management, allowing you to easily activate, deactivate, and monitor your services. Designed to enhance operational efficiency, EFIcyent delivers a user-centric experience that optimizes your business processes and simplifies day-to-day management.
            </p>
          </div>
        );
      };
    
      const Step2 = () => {
        return (
          <div className="tour-step">
            <Image
              src={window.location.origin + "/img/manage-card.svg"}
              className="tour-step-img"
            />
            <p>
            The Manage Account feature provides a clear overview of your current balance, transaction history, and key account details. With everything easily accessible from one screen, tracking your financial activity becomes more efficient, allowing you to stay informed and in control of your finances.
            </p>
          </div>
        );
      };
    
      const Step3 = () => {
        return (
          <div className="tour-step">
            <Image
              src={window.location.origin + "/img/send-money.svg"}
              className="tour-step-img"
            />
            <p>
            Sending money with EFIcyent is fast, secure, and easy through our online platform or mobile app. Benefit from transparent fees, real-time tracking, and advanced security, ensuring your funds reach their destination quickly and safely, providing you with a hassle-free transaction experience.
            </p>
          </div>
        );
      };
    
      const Step4 = () => {
        return (
          <div className="tour-step">
            <Image
              src={window.location.origin + "/img/currency-conversion.svg"}
              className="tour-step-img"
            />
            <p>
            EFIcyent makes international transactions simple with easy currency conversion services. Enjoy competitive exchange rates and transparent fees, allowing you to send money across borders conveniently. Our platform is designed to make global transactions straightforward, cost-effective, and perfectly suited to your financial needs.
            </p>
          </div>
        );
      };
      const Step5 = () => {
        return (
          <div className="tour-step">
            <Image
              src={window.location.origin + "/img/balance-statement.svg"}
              className="tour-step-img"
            />
            <p>
            EFIcyent's balance statement feature provides detailed reports of your account activity, including deposits, withdrawals, and transfers. These comprehensive statements make it easy to monitor your financial health, helping you stay organized and manage your funds more effectively.
            </p>
          </div>
        );
      };
      const Step6 = () => {
        return (
          <div className="tour-step">
            <Image
              src={window.location.origin + "/img/recipients.svg"}
              className="tour-step-img"
            />
            <p>
            A beneficiary is someone designated to receive funds from your transactions. EFIcyent makes managing beneficiaries simple, enabling you to add, update, or modify details with ease, ensuring every transfer is accurate and meets your financial needs for smooth and secure transactions.
            </p>
          </div>
        );
    };
      const Step7 = () => {
        return (
          <div className="tour-step">
            <Image
              src={window.location.origin + "/img/beneficiary-transactions.svg"}
              className="tour-step-img"
            />
            <p>
            With EFIcyent, beneficiary transactions are processed seamlessly and securely. You can monitor the status of your transfers in real-time, receiving notifications to ensure that funds are delivered accurately and on time, providing you peace of mind with every transaction.
            </p>
          </div>
        );
    };
      const Step8 = () => {
        return (
          <div className="tour-step">
            <Image
              src={window.location.origin + "/img/settings.svg"}
              className="tour-step-img"
            />
            <p>
            EFIcyent offers an easy way to personalize your account settings. Update your preferences, adjust security settings, and manage your personal information effortlessly. Our intuitive design ensures a streamlined experience, enabling you to manage your account efficiently and securely without any complexity.
            </p>
          </div>
        );
      };
    
      const steps = [
        {
          target: ".my-first-step",
          title: "Manage Account",
          content: <Step1 />,
          disableBeacon: true,
          placement: "right",
        },
        {
          target: ".my-second-step",
          title: "Virtual Account",
          content: <Step2 />,
          disableBeacon: true,
          placement: "right",
        },
        {
          target: ".my-third-step",
          title: "Send Money",
          content: <Step3 />,
          disableBeacon: true,
          placement: "right",
        },
        {
          target: ".my-fourth-step",
          title: "Currency Conversion",
          content: <Step4 />,
          disableBeacon: true,
          placement: "right",
        },
        {
          target: ".my-fifth-step",
          title: "Balance Statement",
          content: <Step5 />,
          disableBeacon: true,
          placement: "right",
        },
        {
          target: ".my-six-step",
          title: "Beneficiary",
          content: <Step6 />,
          disableBeacon: true,
          placement: "right",
        },
        {
          target: ".my-seven-step",
          title: "Beneficiary Transactions",
          content: <Step7 />,
          disableBeacon: true,
          placement: "right",
        },
        {
          target: ".my-eight-step",
          title: "Settings",
          content: <Step8 />,
          disableBeacon: true,
          placement: "top",
        },
      ];
    
      const handleJoyrideCallback = (data) => {
        if (["next", "skip"].includes(data.action)) {
          if (
            (["skip"].includes(data.action) && data.lifecycle == "complete") ||
            (["next"].includes(data.action) && data.lifecycle == "init")
          ) {
            dispatch(tourStatusUpdateStart());
          }
        }
      };
    return (
        <>
          {profile.data.tour_status == 0 ? (
            <ReactJoyride
              // floaterProps={{ placement: "right" }}
              steps={steps}
              run={true}
              continuous={true}
              spotlightClicks={true}
              scrollToFirstStep={true}
              disableScrolling={true}
              showSkipButton
              showProgress={true}
              disableOverlayClose={true}
              callback={handleJoyrideCallback}
              hideCloseButton={true}
              autoStart
              className="joyride-dashboard"
              styles={{
                options: {
                  borderRadius: "0",
                  arrowColor: "#fff",
                  backgroundColor: "#fff",
                  overlayColor: "#312f2f",
                  primaryColor: "#000",
                  textColor: "#1a1a1a",
                  width: 550,
                  zIndex: 99999,
                },
                buttonNext: {
                  fontSize: "0.9em",
                  padding: "1em 1em",
                  display: "inline-block",
                  backgroundColor: "#0194ff",
                  color: "#fff",
                  border: "0",
                  letterSpacing: ".5px",
                  borderRadius: "0!important",
                  fontWeight: "500",
                  marginLeft: "16px",
                  outline: "none!important",
                  position: "relative",
                },
                overlay: {
                  backgroundColor: "none",
                  mixBlendMode: "unset",
                },
                spotlight: {
                  backgroundColor: "#FFFFFF66",
                  borderRadius: 0,
                  boxShadow: "0px 0px 0px 9999px rgba(0,0,0,0.7)",
                  marginTop: "10px",
                  marginLeft: "0px",
                  maxHeight: "50px",
                  maxWidth: "6.1em",
                },
              }}
              {...props}
            >
              Test
            </ReactJoyride>
          ) : null}
            {/* <div className="side-wrapped"> */}
                
            {
                sidebar ?
                    <Sidebar profile={profile} />
                    : <CollapseSidebar profile={profile} />
            }
            {/* </div> */}
        </>

    );
}