import React, { useEffect, useState } from "react";
import {
  Image,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createTransactionStart } from "../../store/slices/BeneficiariesSlice";
import { useNavigate, Link } from "react-router-dom";
import { ButtonLoader } from "../Helper/Loader";

const TransactionSuccess = (props) => {
  const dispatch = useDispatch();
  const createTransaction = useSelector((state) => state.beneficiaries.createTransaction);
  const beneficiaryList = useSelector((state) => state.beneficiaries.beneficiaryList);
  const sendersList = useSelector((state) => state.senders.sendersList);
  const profile = useSelector((state) => state.admin.profile);
  const [skipRender, setSkipRender] = useState(true);
  const navigate = useNavigate();
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(false);
  const [selectedSender, setSelectedSender] = useState(false);

  useEffect(() => {
    if (
      !skipRender &&
      !createTransaction.loading &&
      Object.keys(createTransaction.data).length > 0
    ) {
      navigate(
        `/beneficiary-transaction-view/${createTransaction.data.beneficiary_transaction.id}`
      );
    }
    setSkipRender(false);
  }, [createTransaction]);

  useEffect(() => {
    if (
      !beneficiaryList.loading &&
      Object.keys(beneficiaryList.data).length > 0
    ) {
      setSelectedBeneficiary(
        beneficiaryList.data.beneficiary_accounts.find(
          (item) => item.id == props.transferData.beneficiary_account_id
        )
      );
    }
    if(!sendersList.loading && Object.keys(sendersList.data).length > 0) {
      props.transferData.sender_id && setSelectedSender(
        sendersList.data.senders.find(
          (item) => item.id == props.transferData.sender_id
        )
      );
    }
  }, []);

  const [timeRemaining, setTimeRemaining] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance =
        new Date(props.transferData.quote.expires_at).getTime() - now;

      if (distance < 0) {
        clearInterval(interval);
        setTimeRemaining("Expired");
      } else {
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        setTimeRemaining(`Expires in ${minutes} ${minutes == 1 ? "minute" : "minutes"}`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [props.transferData.quote.expires_at]);

  return (
    <>
      <div className="single-transaction-final">
        <div className="single-transaction-back">
          <Button
            className="efi-overlay-btn"
            onClick={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default action when pressing Enter
                return;
              }
              props.setStep(props.step - 1);
            }}
            // disabled={updateAddress.buttonDisable}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            disabled={createTransaction.buttonDisable}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 24 24"
            >
              <path
                fill="#0194ff"
                d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Back</span>
          </Button>
        </div>
        <div className="single-avater">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 24 24"
          >
            <g data-name="Layer 2">
              <path
                fill="#2196f3"
                d="M15.14 20L12 22l-4-3a12.22 12.22 0 01-4.89-9.78V5.33a21.29 21.29 0 004.45-1.11A22 22 0 0012 2a22 22 0 004.44 2.22 21.29 21.29 0 004.45 1.11v4.34A12.21 12.21 0 0115.14 20z"
              ></path>
              <path
                fill="#176ca5"
                d="M20.89 5.33v4.34A12.21 12.21 0 0115.14 20L12 22V2a22 22 0 004.44 2.22 21.29 21.29 0 004.45 1.11z"
              ></path>
            </g>
          </svg>
          <h3>Review</h3>
        </div>
        <div className="single-space"></div>
        <div className="single-transaction-box-action">
          {props.transferData.exchange_data ? (
            <React.Fragment>
              <div className="single-transaction-info-card">
                <h4>Exchange rate:</h4>
                <h5>
                  {props.transferData.exchange_data.converting_currency}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 64 64"
                    >
                      <g fill="#0194ff">
                        <path
                          d="M53.121 13.594L36.08 4.25a2.027 2.027 0 00-2.977 1.773v2.9c-13.996.018-22.815 9.592-23.516 17.746l.088 2.693c5.963-6.725 15.16-9.554 22.605-9.216v3.8a2.027 2.027 0 002.81 1.851l17.796-7.639a2.559 2.559 0 00.235-4.564z"
                          data-original="#000000"
                        ></path>
                        <path
                          d="M12.104 29.877l-1.841 1.944a17.582 17.582 0 004.143 6.581.988.988 0 001.665-.725c-.226-4.1.79-10.006 1.86-12.35q-.363.195-.737.416a23.582 23.582 0 00-5.09 4.134zm-1.225 20.529l17.041 9.343a2.027 2.027 0 002.977-1.772v-2.9c13.996-.018 22.815-9.592 23.516-17.746l-.088-2.693c-5.963 6.725-15.16 9.554-22.605 9.216v-3.8a2.027 2.027 0 00-2.81-1.851l-17.796 7.639a2.559 2.559 0 00-.235 4.564z"
                          data-original="#000000"
                        ></path>
                        <path
                          d="M51.896 34.123l1.841-1.944a17.582 17.582 0 00-4.143-6.581.988.988 0 00-1.665.725c.226 4.1-.79 10.006-1.86 12.35q.363-.195.737-.416a23.582 23.582 0 005.09-4.134z"
                          data-original="#000000"
                        ></path>
                      </g>
                    </svg>
                  </span>{" "}
                  {props.transferData.exchange_data.receiving_currency}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        {timeRemaining}
                      </Tooltip>
                    }
                  >
                    <Button className="tooltip-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        data-name="Layer 2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 1a11 11 0 1011 11A11.013 11.013 0 0012 1zm0 20a9 9 0 119-9 9.011 9.011 0 01-9 9z"></path>
                        <path d="M13 11.586V6a1 1 0 00-2 0v6a1 1 0 00.293.707l3 3a1 1 0 001.414-1.414z"></path>
                      </svg>
                    </Button>
                  </OverlayTrigger>
                </h5>
              </div>
              {props.transferData?.quote?.quote?.fees?.platform_fee?.amount ? (
                <div className="single-transaction-info-card">
                  <h4>Fees:</h4>
                  <h5>
                    $
                    {
                      props.transferData.quote?.quote?.fees?.platform_fee
                        ?.amount
                    }
                  </h5>
                </div>
              ) : null}
              {/* <div className="single-transaction-info-card">
                <h4>Total Payable:</h4>
                <h5>
                  ${props.transferData.exchange_data.charged_amount.amount}
                </h5>
              </div> */}
              <div className="quote-info-card mb-3">
                <div className="quote-card">
                  <h5>Delivery Time</h5>
                  <p>
                    Delivery:
                    <span>{props.transferData.quote.quote.delivery_time}</span>
                  </p>
                </div>
                {/* <div className="card-part"></div> */}
                <div className="quote-card">
                  <h5>Charged Amount</h5>
                  <p>
                    {props.transferData.quote.quote.charged_amount.currency}:
                    <span>
                      {props.transferData.quote.quote.charged_amount.amount}
                    </span>
                  </p>
                </div>
                {/* <div className="card-part"></div> */}
                <div className="quote-card">
                  <h5>Converted Amount</h5>
                  <p>
                    {props.transferData.quote.quote.converted_amount.currency}:
                    <span>
                      {props.transferData.quote.quote.converted_amount.amount}
                    </span>
                  </p>
                </div>
                {/* <div className="card-part"></div> */}
                <div className="quote-card">
                  <h5>Recipient Amount</h5>
                  <p>
                    {" "}
                    {
                      props.transferData.quote.quote.recipient_amount.currency
                    } :{" "}
                    <span>
                      {props.transferData.quote.quote.recipient_amount.amount}
                    </span>{" "}
                  </p>
                </div>
                {selectedBeneficiary ? (
                  <div className="quote-card">
                    <h5>Recipient Details</h5>
                    <p>
                      Account Number
                      <span>{selectedBeneficiary.account_number}</span>{" "}
                    </p>
                    <p className="mt-2">
                      Account Name
                      <span>{selectedBeneficiary.account_name}</span>{" "}
                    </p>
                  </div>
                ) : null}
                {selectedSender ? (
                  <div className="quote-card">
                    <h5>Sender Details</h5>
                    <p>
                      Sender Name
                      <span>{selectedSender.first_name + " " + selectedSender.middle_name + " " + selectedSender.last_name}</span>{" "}
                    </p>
                    <p className="mt-2">
                      Unique ID
                      <span>{selectedSender.unique_id}</span>{" "}
                    </p>
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          ) : null}
        </div>
        <div className="single-transaction-box-action w-100">
          <Button
            className="efi-primary-btn  w-100"
            onClick={() =>
              dispatch(
                createTransactionStart(
                  profile.data.sender_status
                    ? {
                        beneficiary_account_id:
                          props.transferData.beneficiary_account_id,
                        quote_id: props.transferData.quote_id,
                        sender_id: props.transferData.sender_id,
                      }
                    : {
                        beneficiary_account_id:
                          props.transferData.beneficiary_account_id,
                        quote_id: props.transferData.quote_id,
                      }
                )
              )
            }
            disabled={createTransaction.buttonDisable}
          >
            {createTransaction.buttonDisable ? (
              <ButtonLoader />
            ) : (
              "Confirm Transaction"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TransactionSuccess;
