import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter,
  useNavigate,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import EmptyLayout, { StaticLayout, AuthLayout } from "../layouts/EmptyLayout";
import { MainLayout } from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import DashBoardIndex from "../DashBoard/DashBoardIndex";
import SettingIndex from "../Setting/SettingIndex";
import PrivacyPolicy from "../Setting/PrivacyPolicy";
import TermsAndConditions from "../Setting/TermsAndConditions";
import ProfileIndex from "../Profile/ProfileIndex";
import PaymentIndex from "../Payment/PaymentIndex";
import ExpenseIndex from "../Expense/ExpenseIndex";
// import AccountIndex from "../Account/AccountIndex";
// import AccountDetailIndex from "../Account/AccountDetailIndex";
import WalletIndex from "../Wallet/WalletIndex";
import { setTranslations, setLanguage } from "react-multi-lang";
import en from "../translations/en.json";
import UserAccessIndex from "../UserAccess/UserAccessIndex";
import OnboardingIndex from "../Onboarding/OnboardingIndex";
import OnboardingWelcome from "../Onboarding/OnboardingWelcome";
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ForgotPassword from "../Auth/ForgotPassword";
import ResetPassword from "../Auth/ResetPassword";
import { AdminLayout, OnboardingLayout } from "../layouts";
import CreateAccountIndex from "../Account/CreateAccountIndex";
import AccountViewDetails from "../Account/AccountViewDetails";
import ContactIndex from "../Contact/ContactIndex";
import BillingAddress from "../Setting/BillingAddress";
import CardIndex from "../Card/CardIndex";
import BeneficiaryIndex from "../Payment/BeneficiaryIndex";
import ContactViewDetails from "../Contact/ContactView";
import InvoiceIndex from "../Invoice/RecipientsIndex";
import TransactionHistory from "../Payment/TransactionHistory";
import RecipientsIndex from "../Invoice/RecipientsIndex";
import StaticPageIndex from "../StaticPages/PrivacyPolicyIndex";
import BusinessUserList from "../BusinessInfo/BusinessUserList";
import BusinessUserListView from "../BusinessInfo/BusinessUserListView";
import Page404 from "../Helper/Page404";
import CreateBusinessInfo from "../BusinessInfo/CreateBusinessInfo";
import VirtualAccount from "../VirtualAccount/VirtualAccount";
import DashboardIndex from "../DashBoard/DashBoardIndex";
import BeneficiaryList from "../Beneficiary/BeneficiaryList";
import CreateBeneficiary from "../Beneficiary/CreateBeneficiary";
import ViewBeneficiary from "../Beneficiary/ViewBeneficiary";
import Beneficiary from "../Payment/BeneficiaryIndex";
import QuoteIndex from "../Quote/QuoteIndex";
import QuoteView from "../Quote/QuoteView";
import BeneficiaryTransactions from "../Beneficiary/BeneficiaryTransactions";
import BeneficiaryView from "../Beneficiary/BeneficiaryTransactionView";
import SingleTransactionIndex from "../Transaction/SingleTransactionIndex";
import CurrencyConversionIndex from "../CurrencyConversion/CurrencyConversionIndex";
import BalanceStatement from "../Statement/BalanceStatement";
import SenderList from "../SingleSender/SenderList";
import SenderViewDetail from "../SingleSender/SenderViewDetail";
import AddSender from "../SingleSender/AddSender";



const $ = window.$;
setTranslations({ en });
const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  // let userId = localStorage.getItem("userId");
  let accessToken =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  // let userLoginStatus = localStorage.getItem("userLoginStatus");
  // let authentication =accessToken
  // userId && accessToken && userLoginStatus == "true" ? true : false;

  // Timeout Functionality

  const navigate = useNavigate();
  // useEffect(() => {
  //   let timeoutId;

  //   const resetTimeout = () => {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       sessionStorage.removeItem("token");
  //       navigate("/login");
  //     }, 60000); // 60 seconds in milliseconds
  //   };

  //   const handleActivity = () => {
  //     resetTimeout();
  //   };

  //   window.addEventListener("click", handleActivity);
  //   window.addEventListener("keydown", handleActivity);

  //   resetTimeout();

  //   return () => {
  //     window.removeEventListener("click", handleActivity);
  //     window.removeEventListener("keydown", handleActivity);
  //     clearTimeout(timeoutId);
  //   };
  // }, [navigate]);

  return accessToken ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/login" }} />
  );
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
        />
        <script src={configuration.get("configData.head_scripts")}></script>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path={"/"}>
            <Route
              index={true}
              element={
                <PrivateRoute component={DashBoardIndex} layout={AdminLayout} />
              }
            />
          </Route>
          <Route
            path={"/dashboard"}
            element={
              <PrivateRoute component={DashBoardIndex} layout={AdminLayout} />
            }
          />
          <Route
            path={"/profile"}
            element={
              <PrivateRoute component={ProfileIndex} layout={AdminLayout} />
            }
          />
          <Route
            path={"/payment"}
            element={
              <PrivateRoute component={PaymentIndex} layout={AdminLayout} />
            }
          />
          <Route
            path={"/settings"}
            element={
              <PrivateRoute component={SettingIndex} layout={AdminLayout} />
            }
          />
          <Route
            path={"/expense"}
            element={<AppRoute component={ExpenseIndex} layout={AdminLayout} />}
          />
          {/* <Route
            path={"/account"}
            element={
              <PrivateRoute component={CreateAccountIndex} layout={AdminLayout} />
            }
          /> */}
          <Route
            path={"/account"}
            element={
              <PrivateRoute component={DashboardIndex} layout={AdminLayout} />
            }
          />
          <Route
            path={"/account-details"}
            element={
              <PrivateRoute
                component={AccountViewDetails}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/wallet"}
            element={<AppRoute component={WalletIndex} layout={AdminLayout} />}
          />
          <Route
            path={"/user-access"}
            element={
              <AppRoute component={UserAccessIndex} layout={AdminLayout} />
            }
          />

          {/* New Flow  */}

          <Route
            path={"/onboarding"}
            element={
              <AppRoute component={OnboardingWelcome} layout={AdminLayout} />
            }
          />
          <Route
            path={"/user-onboarding"}
            element={
              <AppRoute component={OnboardingIndex} layout={OnboardingLayout} />
            }
          />

          <Route
            path={"/login"}
            element={<AppRoute component={LoginIndex} layout={AuthLayout} />}
          />
          <Route
            path={"/register"}
            element={<AppRoute component={RegisterIndex} layout={AuthLayout} />}
          />
          <Route
            path={"/forgot-password"}
            element={
              <AppRoute component={ForgotPassword} layout={AuthLayout} />
            }
          />

          <Route
            path={"/reset-password"}
            element={
              <AppRoute component={ResetPassword} layout={AuthLayout} />
            }
          />
          <Route
            path={"/contact-details"}
            element={
              <PrivateRoute component={ContactIndex} layout={AdminLayout} />
            }
          />
          <Route
            path={"/contact-details-view/:id"}
            element={
              <PrivateRoute
                component={ContactViewDetails}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/billing-address"}
            element={
              <PrivateRoute component={BillingAddress} layout={AdminLayout} />
            }
          />
          {/* <Route
            path={"/card-details"}
            element={
              <PrivateRoute component={CardIndex} layout={AdminLayout} />
            }
          />
          <Route
            path={"/transactions"}
            element={
              <PrivateRoute
                component={TransactionHistory}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/recipients"}
            element={
              <PrivateRoute component={ContactIndex} layout={AdminLayout} />
            }
          /> */}
          <Route
            path={"/beneficiary"}
            element={
              <PrivateRoute component={BeneficiaryList} layout={AdminLayout} />
            }
          />
          <Route
            path={"/beneficiary-transactions"}
            element={
              <PrivateRoute
                component={BeneficiaryTransactions}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/beneficiary-transaction-view/:id"}
            element={
              <PrivateRoute component={BeneficiaryView} layout={AdminLayout} />
            }
          />
          <Route
            path={"/create-beneficiary"}
            element={
              <PrivateRoute
                component={CreateBeneficiary}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/view-beneficiary/:id"}
            element={
              <PrivateRoute component={ViewBeneficiary} layout={AdminLayout} />
            }
          />
          <Route
            path={"/business-user-list"}
            element={
              <PrivateRoute component={BusinessUserList} layout={AdminLayout} />
            }
          />
          <Route
            path={"/business-user-list-view/:id"}
            element={
              <PrivateRoute
                component={BusinessUserListView}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/create-business-profile"}
            element={
              <AppRoute component={CreateBusinessInfo} layout={AdminLayout} />
            }
          />
          <Route
            path={"/virtual-account"}
            element={
              <AppRoute component={VirtualAccount} layout={AdminLayout} />
            }
          />
          {/* <Route
            path={"/quote"}
            element={<AppRoute component={QuoteIndex} layout={AdminLayout} />}
          /> */}
          <Route
            path={"/quote-details"}
            element={<AppRoute component={QuoteView} layout={AdminLayout} />}
          />
          {/* Transaction Pages  */}
          <Route
            path={"/send-money"}
            element={<AppRoute component={SingleTransactionIndex} layout={AdminLayout} />}
          />
          <Route
            path={"/currency-conversion"}
            element={<AppRoute component={CurrencyConversionIndex} layout={AdminLayout} />}
          />
          <Route
            path={"/balance-statement"}
            element={<AppRoute component={BalanceStatement} layout={AdminLayout} />}
          />
            <Route
            path={"/sender-list"}
            element={<AppRoute component={SenderList} layout={AdminLayout} />}
          />
           <Route
            path={"/add-sender"}
            element={<AppRoute component={AddSender} layout={AdminLayout} />}
          />
           <Route
            path={"/view-sender-detail/:id"}
            element={<AppRoute component={SenderViewDetail} layout={AdminLayout} />}
          />
          <Route
            path={"/page/:unique_id"}
            element={
              <AppRoute component={StaticPageIndex} layout={StaticLayout} />
            }
          />
          <Route
            path={"/*"}
            element={<AppRoute component={Page404} layout={EmptyLayout} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
