import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  sendersList: getCommonInitialState(),
  senderCreate: getCommonInitialState(),
  senderView: getCommonInitialState(),
};

const SendersSlice = createSlice({
  name: "senders",
  initialState,
  reducers: {

    sendersListStart: (state) => { state.sendersList = getCommonStart()},
    sendersListSuccess: (state, action) => { state.sendersList = getCommonSuccess(action.payload)},
    sendersListFailure: (state, action) => { state.sendersList = getCommonFailure(action.payload)},

    senderCreateStart: (state) => { state.senderCreate = getCommonStart()},
    senderCreateSuccess: (state, action) => { state.senderCreate = getCommonSuccess(action.payload)},
    senderCreateFailure: (state, action) => { state.senderCreate = getCommonFailure(action.payload)},

    senderViewStart: (state) => { state.senderView = getCommonStart()},
    senderViewSuccess: (state, action) => { state.senderView = getCommonSuccess(action.payload)},
    senderViewFailure: (state, action) => { state.senderView = getCommonFailure(action.payload)},

  },
});

export const {
  sendersListStart,
  sendersListSuccess,
  sendersListFailure,
  senderCreateFailure,
  senderCreateStart,
  senderCreateSuccess,
  senderViewFailure,
  senderViewStart,
  senderViewSuccess,
} = SendersSlice.actions;

export default SendersSlice.reducer;
