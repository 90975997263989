import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Form, Row, Col } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Field, Form as FORM, Formik, ErrorMessage } from "formik";
import Select from "react-select";
import { Radio } from "pretty-checkbox-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as Yup from "yup";
import { countries } from "countries-list";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  getCountries,
} from "react-phone-number-input";
import { useTranslation } from "react-multi-lang";
import iso from "iso-3166-1";
import { useDispatch, useSelector } from "react-redux";
import { senderCreateStart } from "../../store/slices/SendersSlice";
import { ButtonLoader } from "../Helper/Loader";
import { useNavigate } from "react-router-dom";

const filteredCountries = getCountries().filter(
  (country) =>
    ![
      // "AF",
      // "BY",
      // "CF",
      // "CN",
      // "CU",
      // "CD",
      // "ET",
      // "IN",
      // "IR",
      // "IQ",
      // "LY",
      // "ML",
      // "NI",
      // "KP",
      // "SO",
      // "SS",
      // "SD",
      // "SY",
      // "US",
      // "VE",
      // "YE",
      // "ZW",
    ].includes(country)
);

const countryOptions = Object.keys(countries)
  .filter((country) =>
    [
      "AT",
      "AD",
      "AU",
      "BD",
      "BE",
      "BG",
      "CH",
      "CN",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FR",
      "GB",
      "GR",
      "HK",
      "HR",
      "HU",
      "ID",
      "IE",
      "IN",
      "IS",
      "IT",
      "JP",
      "LI",
      "LK",
      "LT",
      "LU",
      "LV",
      "MC",
      "MT",
      "MY",
      "NL",
      "NO",
      "PH",
      "PL",
      "PT",
      "RO",
      "SE",
      "SI",
      "SK",
      "SM",
      "TH",
      "US",
      "VA",
      "VN",
    ].includes(country)
  )
  .map((alpha2) => ({
    value: alpha2,
    label: countries[alpha2].name,
  }));

const AddSender = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("beneficiaries.create_beneficiaries");
  const [country, setCountry] = useState("");
  const [senderType, setSenderType] = useState(1);
  const [skipRender, setSkipRender] = useState(true);
  const senderCreate = useSelector((state) => state.senders.senderCreate);
  const profile = useSelector((state) => state.admin.profile);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid  #DDE1E6 !important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      color: "#757575",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   return {
    //     ...styles,
    //     backgroundColor: isSelected ? "#f7f7f7" : "#fff",
    //     color: "#000",
    //   };
    // },
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#0194ff" : "#fff",
      color: state.isFocused || state.isSelected ? "#fff" : "#000",
      ":hover": {
        backgroundColor: "#0194ff",
        color: "#fff",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    unique_id: Yup.string()
      .required(t("required"))
      .min(1, "Must have 1 characters at minimum")
      // .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, "Invalid Unique ID")
      .matches(/^[a-zA-Z0-9_-]+$/, "Invalid Unique ID")
      .trim(),
    first_name: Yup.string()
      .required(t("required"))
      .min(1, "Must have 1 characters at minimum")
      .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, "Invalid first name")
      .trim(),
    middle_name: Yup.string()
      .min(1, "Must have 1 characters at minimum")
      .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, "Invalid middle name")
      .trim(),
    last_name: Yup.string()
      .required(t("required"))
      .min(1, "Must have 1 characters at minimum")
      .matches(/^\S.*$/, t("required"))
      .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, "Invalid last name")
      .trim(),
    email: Yup.string()
      .email(t("email.invalid"))
      //   .required(t("required"))
      .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, t("invalid"))
      .trim(),
    country: Yup.string().required(t("required")),
    address: Yup.string()
      .required(t("required"))
      .min(1, "Must have 1 characters at minimum")
      .matches(/^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/, t("invalid")),
    postal_code: Yup.string()
      .strict(true) // ensures that trimming won't happen automatically
      .matches(/^\S+$/, "No spaces are allowed") // no spaces allowed
      .required(t("required"))
      .min(1, "Must have 1 characters at minimum"),
    mobile: Yup.string()
      .test("valid-mobile", t("mobile.invalid"), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(t("required"))
      .min(1, "Must have 1 characters at minimum")
      .matches(/^(?=.*[0-9])/, t("mobile_number.space_values_not_valid")),
    source_of_funds: Yup.string()
      .min(1, "Must have 1 characters at minimum")
      .matches(/^\S.*$/, t("required"))
      .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, "Invalid")
      .trim(),
  });

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile_number.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      dispatch(
        senderCreateStart({
          ...values,
          mobile: mobile,
          mobile_country_code: countryCode,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !senderCreate.loading &&
      Object.keys(senderCreate.data).length > 0
    ) {
      navigate("/sender-list");
    }
    setSkipRender(false);
  }, [senderCreate]);

  return (
    <div className="full-body-card-wrapped">
      <div className="contact-list-card-area">
        <Formik
          initialValues={{
            type: 1,
            unique_id: "",
            first_name: "",
            last_name: "",
            middle_name: "",
            email: "",
            mobile: "",
            address: "",
            postal_code: "",
            country: "",
            source_of_funds: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          innerRef={formRef}
        >
          {({ errors, touched, values, setFieldValue, setValues }) => (
            <FORM className="create-beneficiary-from">
              <Row>
                <Col md={12} lg={6} xl={6}>
                  <div className="sender-from-box">
                    <div className="beneficiary-from-heading">
                      <h3>Sender Type</h3>
                    </div>
                    <Col md={12} className="mt-3">
                      <Form.Group className="mb-3">
                        <Radio
                          name="radio"
                          checked={values.type == 1}
                          onClick={() => setFieldValue("type", 1)}
                        >
                          Individual
                        </Radio>
                        <Radio
                          name="radio"
                          checked={values.type == 2}
                          onClick={() => setFieldValue("type", 2)}
                        >
                          Business
                        </Radio>
                      </Form.Group>
                    </Col>
                  </div>
                </Col>
                <Col md={12} lg={12} xl={12}>
                  {senderType ? (
                    <div className="sender-from-box">
                      <Row>
                        <React.Fragment>
                          <div className="beneficiary-from-heading">
                            <h3>Sender Details</h3>
                          </div>
                          <Col md={12} lg={6} xl={6}>
                            <div className="sender-from-box">
                              <Row>
                                <Col sm={12} lg={12} xl={12}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("unique_id.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="unique_id"
                                      placeholder={t("unique_id.placeholder")}
                                      maxLength={50}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="unique_id"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={4}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("first_name.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="first_name"
                                      placeholder={t("first_name.placeholder")}
                                      maxLength={50}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="first_name"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={4}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("middle_name.name")}
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="middle_name"
                                      placeholder={t("middle_name.placeholder")}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="middle_name"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={4}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("last_name.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="last_name"
                                      placeholder={t("last_name.placeholder")}
                                      maxLength={50}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="last_name"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={12} lg={12} xl={6}>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>{t("email.name")}</Form.Label>
                                    <Field
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      placeholder={t("email.placeholder")}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="email"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={6}>
                                  <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>
                                      {t("mobile_number.name")} <span>*</span>
                                    </Form.Label>
                                    <PhoneInput
                                      defaultCountry={profile.data.nationality}
                                      placeholder={t(
                                        "mobile_number.placeholder"
                                      )}
                                      value={values.mobile}
                                      onChange={(value) =>
                                        setFieldValue("mobile", value)
                                      }
                                      onBlur={() =>
                                        formRef.current.submitForm()
                                      }
                                      international
                                      countries={filteredCountries}
                                      countryCallingCodeEditable={false}
                                      maxLength={31}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="mobile"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={12} lg={6} xl={6}>
                            <div className="sender-from-box">
                              <Row>
                                <Col sm={12} lg={12} xl={12}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("address.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      className="form-control"
                                      name="address"
                                      // as="textarea"
                                      placeholder={`${t(
                                        "address.placeholder"
                                      )}`}
                                      // rows={2}
                                      maxLength={30}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="address"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={6}>
                                  <Form.Group controlId="formBasicEmail" className="mb-3">
                                    <Form.Label>
                                      {t("zip_code.name")}
                                      <span>*</span>
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name="postal_code"
                                      placeholder={t("zip_code.placeholder")}
                                      maxLength={20}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="postal_code"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={6}>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>
                                      {t("country")} <span>*</span>
                                    </Form.Label>
                                    <Select
                                      options={countryOptions}
                                      value={country}
                                      styles={customStyles}
                                      onChange={(selectedOption) => {
                                        setCountry(selectedOption);
                                        setFieldValue(
                                          "country",
                                          selectedOption.value
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="country"
                                      className="text-danger text-right"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col sm={12} lg={12} xl={12}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {t("source_of_funds.name")}
                                    </Form.Label>
                                    <Field
                                      className="form-control"
                                      name="source_of_funds"
                                      placeholder={`${t(
                                        "source_of_funds.placeholder"
                                      )}`}
                                      // rows={2}
                                      maxLength={30}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="source_of_funds"
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={12} lg={12} className="mt-3">
                            <div className="create-beneficiary-btn-sec">
                              <Button
                                className="efi-overlay-btn"
                                onClick={() => navigate("/beneficiary")}
                              >
                                {t("cancel")}
                              </Button>
                              <Button
                                className="efi-primary-btn"
                                type="submit"
                                disabled={senderCreate.buttonDisable}
                              >
                                {senderCreate.buttonDisable ? (
                                  <ButtonLoader />
                                ) : (
                                  t("create")
                                )}
                              </Button>
                            </div>
                          </Col>
                        </React.Fragment>
                      </Row>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LazyLoadImage
                        effect="blur"
                        className="create-account-avater mt-3"
                        src={window.location.origin + "/img/sender-no-data.svg"}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </FORM>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddSender;

{
  /* <Col md={12} lg={12} xl={12}>
                {senderType === "individual" && (
                  <div className="recipient-from-box">
                    <div className="beneficiary-from-heading">
                      <h3>Individual</h3>
                    </div>
                    <Row>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            Sender Country <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select Country"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="recipient_country"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            First Name <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter First Name"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Last Name <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter Last Name"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Sender Unique ID <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter Sender ID"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Address <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter Address"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Postal Code <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter Postal Code"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Phone Country Code <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select Country Code"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Phone Number <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter  Phone"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Sender's Email <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter Sender Email"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            Source of Fund <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select Country"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="recipient_country"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Sender ID Type <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select ID Type"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Sender ID Number <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter ID Number"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            ID Expiry Date <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder=""
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Nationality <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select ID Type"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Occupation <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select ID Type"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            Date of Birth <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder=""
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} lg={12} className="mt-3">
                        <div className="create-beneficiary-btn-sec">
                          <Button className="efi-overlay-btn">Cancel</Button>
                          <Button className="efi-primary-btn" type="submit">
                            Create
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                {senderType === "business" && (
                  <div className="recipient-from-box">
                    <div className="beneficiary-from-heading">
                      <h3>Business</h3>
                    </div>
                    <Row>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            Sender Country <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select Country"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="recipient_country"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Your Unique Sender ID <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter Sender ID"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Business Name <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter First Name"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Address <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter Address"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Postal Code <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter Postal Code"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Phone Country Code <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select Country Code"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Phone Number <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter  Phone"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Sender's Email <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter Sender Email"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>
                            Source of Fund <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select Country"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="recipient_country"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Business Registration Number <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder="Enter ID Number"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Category of Business <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select ID Type"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Nature of Business <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder=""
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Authorized Person ID Type <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select ID Type"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Authorized Person ID Number <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder=""
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Authorized Person Nationality <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select ID Type"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Authorized Person Date of Birth <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder=""
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Authorized Person Name <span>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="state"
                            placeholder=""
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} lg={4} xl={4}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Designation <span>*</span>
                          </Form.Label>
                          <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            placeholder="Select ID Type"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="state"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} lg={12} className="mt-3">
                        <div className="create-beneficiary-btn-sec">
                          <Button className="efi-overlay-btn">Cancel</Button>
                          <Button className="efi-primary-btn" type="submit">
                            Create
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col> */
}
